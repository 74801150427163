

const initialState = {
  user: {},
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_LOGOUT': {
      const { params: { not_allowed } } = action.payload;
      if (not_allowed) {
        return {
          ...initialState,
          not_allowed: true,
        };
      }
      return initialState;
    }

    case "ON_SIDEBAR_TOGGLE": {
      const { open } = action.payload;
      return {
        ...state,
        sideBarOpen: open
      };
    }

    case 'SET_LOGGED_IN': {
      const { uid, loggedIn, loggedOut } = action.payload;
      return {
        ...state,
        user: {
          ...state.user,
          uid,
          loggedIn,
          loggedOut,
        }
      };
    }

  }
  return state;
};
export default userReducer;
