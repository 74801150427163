import * as guideHelper from './guideHelper';

const initialState = {
  locations: {},
};

const engageReducer = (state = initialState, action) => {
  switch (action.type) {
    // case 'USER_LOGOUT': {
    //   return initialState;
    // }
    case 'GET_LOCATION_INFO_CLEAR': {
      const { params: { location__id } } = action.payload;
      if (!location__id) return state;
      return {
        ...state,
        locations: {
          ...state.locations,
          [location__id]: state.locations[location__id]
            ? {
                ...state.locations[location__id],
                getDataInProgress: false,
                getDataStatus: {},
              }
            : {
                getDataInProgress: false,
                getDataStatus: {},
              }
        },
      };
    }
    case 'GET_LOCATION_INFO': {
      const { params: { location__id } } = action.payload;
      if (!location__id) return state;
      return {
        ...state,
        locations: {
          ...state.locations,
          [location__id]: {
            ...state.locations[location__id],
            getDataInProgress: true,
            getDataStatus: {},
          }
        },
      };
    }
    case 'GET_LOCATION_INFO_SUCCESS': {
      const { data, params: { location__id } } = action.payload;
      if (!location__id) return state;
      const { location_info } = data;
      return {
        ...state,
        locations: {
          ...state.locations,
          [location__id]: {
            ...state.locations[location__id],
            location_data: location_info || {},
            getDataInProgress: false,
            getDataStatus: {
              success: true,
            },
          }
        },
      };
    }
    case 'GET_LOCATION_INFO_ERROR': {
      const { error, errorMessage, params } = action.payload;
      if (!params) return state;
      const { location__id } = params;
      if (!location__id) return state;
      return {
        ...state,
        locations: {
          ...state.locations,
          [location__id]: {
            ...state.locations[location__id],
            location_data: {},
            getDataInProgress: false,
            getDataStatus: {
              error: true,
            },
          },
        },
      };
    }


    case "INIT_GUIDE": {
      return guideHelper.initGuide(state, action);
    }

    case 'GET_GUIDE_HOTEL_INFO_CLEAR': {
      return guideHelper.getGuideHotelInfoClear(state, action);
    }
    case 'GET_GUIDE_HOTEL_INFO': {
      return guideHelper.getGuideHotelInfo(state, action);
    }
    case 'GET_GUIDE_HOTEL_INFO_SUCCESS': {
      return guideHelper.getGuideHotelInfoSuccess(state, action);
    }
    case 'GET_GUIDE_HOTEL_INFO_ERROR': {
      return guideHelper.getGuideHotelInfoError(state, action);
    }


    /* Get guide room info */

    case 'GET_GUIDE_ROOM_INFO_CLEAR': {
      return guideHelper.getGuideRoomInfoClear(state, action);
    }
    case 'GET_GUIDE_ROOM_INFO': {
      return guideHelper.getGuideRoomInfo(state, action);
    }
    case 'GET_GUIDE_ROOM_INFO_SUCCESS': {
      return guideHelper.getGuideRoomInfoSuccess(state, action);
    }
    case 'GET_GUIDE_ROOM_INFO_ERROR': {
      return guideHelper.getGuideRoomInfoError(state, action);
    }


    default:
  }
  return state;
}
export default engageReducer;
