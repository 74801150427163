import * as engageHelper from './engageHelper';

const initialState = {
  guest_data: {},
};

const engageReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'USER_LOGOUT': {
      return initialState;
    }
    case 'GET_LOCATION_INFO_SUCCESS': {
      const { data, params: { location__id } } = action.payload;
      if (!location__id) return state;
      const { location_info, unread_count } = data;
      return {
        ...state,
        guest_data: {
          ...state.guest_data,
          unreadCount: unread_count ? unread_count : 0,
        },
      };
    }
    case 'GET_GUEST': {
      const { params: { location__id, guest_id } } = action.payload;
      if (!location__id || !guest_id) return state;
      return {
        ...state,
        guest_data: {
          ...state.guest_data,
          getGuestInProgress: true,
          getGuestStatus: {},
        },
      };
    }
    case 'GET_GUEST_SUCCESS': {
      const { data, params: { location__id, guest_id } } = action.payload;
      if (!location__id || !guest_id) return state;
      const { guest_data } = data;
      return {
        ...state,
        guest_data: {
          ...state.guest_data,
          data: guest_data || {},
          getGuestInProgress: false,
          getGuestStatus: {
            success: true,
          },
          unreadCount: guest_data && guest_data.unread_count ? guest_data.unread_count : 0,
        },
      };
    }
    case 'GET_GUEST_ERROR': {
      const { error, errorMessage, params } = action.payload;
      if (!params) return state;
      const { location__id, guest_id } = params;
      if (!location__id || !guest_id) return state;
      return {
        ...state,
        guest_data: {
          ...state.guest_data,
          getGuestInProgress: false,
          getGuestStatus: {
            error: true,
          },
          error,
          errorMessage,
        },
      };
    }

    /*********** Send Message - Start ********************/

    case 'SEND_MESSAGE_CLEAR': {
      return {
        ...state,
        guest_data: {
          ...state.guest_data,
          sendMessageInProgress: false,
          sendMessageStatus: {},
        },
      };
    }

    case 'SEND_MESSAGE': {
      const { params: { location__id, guest_id, outside_guest_id, msg, new_msg_id } } = action.payload;
      if (!location__id || (!guest_id && !outside_guest_id) || !msg || !new_msg_id) return state;
      const message = {
        _id: new_msg_id,
        location__id,
        msg,
        type: 1,
        date_created: new Date(),
        sendMessageInProgress: true,
      };
      return {
        ...state,
        guest_data: {
          ...state.guest_data,
          data: {
            ...state.guest_data.data,
            messages: state.guest_data.data.messages ? [message, ...state.guest_data.data.messages] : [message],
          },
          sendMessageInProgress: true,
          sendMessageStatus: {},
        },
      };
    }

    case 'SEND_MESSAGE_SUCCESS': {
      const { data, params: { location__id, guest_id, outside_guest_id, msg, new_msg_id } } = action.payload;
      if (!location__id || (!guest_id && !outside_guest_id) || !msg || !new_msg_id) return state;
      const { message } = data;
      if (!message) return state;

      let existingMessages = [ ...state.guest_data.data.messages ].filter(el => el._id !== new_msg_id);
      let messageFound = false;
      existingMessages.forEach((item) => {
        if (item._id === message._id) {
          messageFound = true;
        }
      });
      if (!messageFound) {
        existingMessages = [message, ...existingMessages];
      }

      return {
        ...state,
        guest_data: {
          ...state.guest_data,
          data: {
            ...state.guest_data.data,
            messages: existingMessages,
          },
          sendMessageInProgress: false,
          sendMessageStatus: {
            success: true,
          },
        },
      };
    }

    case 'SEND_MESSAGE_ERROR': {
      const { error, errorMessage } = action.payload;
      const { params: { location__id, guest_id, outside_guest_id, msg, new_msg_id } } = action.payload;
      if (!location__id || (!guest_id && !outside_guest_id) || !msg || !new_msg_id) return state;

      let existingMessages = [ ...state.guest_data.data.messages ];
      let messageFound = false;
      existingMessages.forEach((item) => {
        if (item._id === new_msg_id) {
          item.sendMessageInProgress = false;
          item.sendMessageStatus = {
            error: true,
          };
        }
      });

      return {
        ...state,
        guest_data: {
          ...state.guest_data,
          data: {
            ...state.guest_data.data,
            messages: existingMessages,
          },
          sendMessageInProgress: false,
          sendMessageStatus: {
            error: true,
          },
        },
      };
    }


    /*********** Send Message - End ********************/


    /************** Mark message as read - Start *********************/

    case 'MARK_MESSAGE_AS_READ_CLEAR': {
      return {
        ...state,
        guest_data: {
          ...state.guest_data,
          markMessageAsReadInProgress: false,
          markMessageAsReadStatus: {},
        },
      };
    }

    case 'MARK_MESSAGE_AS_READ': {
      const { params: { location__id, guest_id, outside_guest_id, all, msg_id, msg_id_list } } = action.payload;
      if (!location__id || (!guest_id && !outside_guest_id && !msg_id && !msg_id_list)) return state;
      return {
        ...state,
        guest_data: {
          ...state.guest_data,
          markMessageAsReadInProgress: false,
          markMessageAsReadStatus: {},
        },
      };
    }

    case 'MARK_MESSAGE_AS_READ_SUCCESS': {
      const { data, params: { location__id, guest_id, outside_guest_id, all, msg_id, msg_id_list } } = action.payload;
      if (!location__id || (!guest_id && !outside_guest_id && !msg_id && !msg_id_list)) return state;
      // const { message } = data;
      // if (!message) return state;

      let existingMessages = [ ...state.guest_data.data.messages ];
      // let messageFound = false;
      existingMessages.forEach((item) => {
        if (item._id === msg_id) {
          item.webapp_read = true;
        }
      });

      return {
        ...state,
        guest_data: {
          ...state.guest_data,
          data: {
            ...state.guest_data.data,
            messages: existingMessages,
          },
          unreadCount: 0,
          markMessageAsReadInProgress: false,
          markMessageAsReadStatus: {
            success: true,
          },
        },
      };
    }

    case 'MARK_MESSAGE_AS_READ_ERROR': {
      const { error, errorMessage } = action.payload;
      const { params: { location__id, guest_id, outside_guest_id, all, msg_id, msg_id_list } } = action.payload;

      return {
        ...state,
        guest_data: {
          ...state.guest_data,
          markMessageAsReadInProgress: false,
          markMessageAsReadStatus: {
            error: true,
          },
          error,
          errorMessage,
        },
      };
    }

    /************** Mark message as read - End *********************/



    case 'HANDLE_ENGAGE_MESSAGE': {
      if (process.env.NODE_ENV !== 'production') {
        console.log('handleEngageMessage: ', action.payload); // comment
      }
      const {
        type, data, loggedInUid, locationidentifier
      } = action.payload;

      if (
        !type ||
        (
          type !== 'guest-message-create' &&
          type !== 'outside-guest-message-create' &&
          type !== 'message-update' &&
          type !== 'typing-update' &&
          type !== 'contact-typing-update'
        )
      ) { return state; }
      if (!data) return state;


      // guest message create
      if (type === 'guest-message-create') {
        return engageHelper.handleGuestMessageCreateMessage(state, action);
      }
      return state;
    }

    default:
  }
  return state;
}
export default engageReducer;
