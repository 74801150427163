
// guest message create
export const handleGuestMessageCreateMessage = (state, action) => {
  if (process.env.NODE_ENV !== 'production') {
    console.log('handleGuestMessageCreateMessage: ', action.payload); // comment
  }
  const { type, data, loggedInUid, locationidentifier } = action.payload;


  if (!state.guest_data.data) return state;

  const { message } = data;
  const { msg, name } = message;
  // let guest_name = '';
  const {
    _id,
    location_id,
    guest_id,
    new_msg_id,
    // outside_guest_id,
  } = message;
  if (process.env.NODE_ENV !== 'production') {
    console.log('_id,  location_id,guest_id,: ', _id, location_id, guest_id);
  }
  if (!_id || !location_id || !guest_id) return state;


  const existingMessages = state.guest_data.data.messages || [];
  if (existingMessages && existingMessages.find(el => el._id === _id || el._id === new_msg_id)) {
    if (process.env.NODE_ENV !== 'production') {
      console.log('message already exists');
    }
    return state;
  }

  let newUnreadCount = state.guest_data.unreadCount || 0;
  if (message.type === 2) {
    newUnreadCount++;
  }

  return {
    ...state,
    guest_data: {
      ...state.guest_data,
      data: {
        ...state.guest_data.data,
        latest_msg: msg,
        date_last_message: message.date_created,
        date_last_message_sent: message.date_created,
        messages: state.guest_data.data.messages ? [message, ...state.guest_data.data.messages] : [message],
      },
      unreadCount: newUnreadCount,
      alertSoundStatus: message.type === 2 ? 'play' : 'played',
    },
  };
}
