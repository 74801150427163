
export const initGuide = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        guide: state.locations[location__id].guide
          ?
            {
              ...state.locations[location__id].guide,
            }
          : {}
      },
    },
  };
};


/***************************************** Get Guide Hotel Info ****************************/

export const getGuideHotelInfoClear = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        guide: {
          ...state.locations[location__id].guide,
          hotel_info: state.locations[location__id].guide.hotel_info
          ?
            {
              ...state.locations[location__id].guide.hotel_info,
              getInProgress: false, getStatus: {},
            }
          : {
              getInProgress: false, getStatus: {},
            }
        },
      },
    },
  };
};

export const getGuideHotelInfo = (state, action) => {
  const { params: { location__id } } = action.payload;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        guide: {
          ...state.locations[location__id].guide,
          hotel_info: {
            ...state.locations[location__id].guide.hotel_info,
            getInProgress: true, getStatus: {},
          },
        },
      },
    },
  };
};

export const getGuideHotelInfoSuccess = (state, action) => {
  const { data, params: { location__id } } = action.payload;
  if (!location__id) return state;
  let { digital_guide_pages } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        guide: {
          ...state.locations[location__id].guide,
          hotel_info: {
            ...state.locations[location__id].guide.hotel_info,
            pages: digital_guide_pages,
            getInProgress: false, getStatus: {
              success: true,
            },
          },
        },
      },
    },
  };
};

export const getGuideHotelInfoError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id }= params;
  if (!location__id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        guide: {
          ...state.locations[location__id].guide,
          hotel_info: {
            ...state.locations[location__id].guide.hotel_info,
            error,
            errorMessage,
            getInProgress: false, getStatus: {
              error: true,
            },
          },
        },
      },
    },
  };
};


/***************************************** Get Guide Room Info ****************************/

export const getGuideRoomInfoClear = (state, action) => {
  const { params: { location__id, guest_id } } = action.payload;
  if (!location__id || !guest_id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        guide: {
          ...state.locations[location__id].guide,
          room_info: state.locations[location__id].guide.room_info
          ?
            {
              ...state.locations[location__id].guide.room_info,
              getInProgress: false, getStatus: {},
            }
          : {
              getInProgress: false, getStatus: {},
            }
        },
      },
    },
  };
};

export const getGuideRoomInfo = (state, action) => {
  const { params: { location__id, guest_id } } = action.payload;
  if (!location__id || !guest_id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        guide: {
          ...state.locations[location__id].guide,
          room_info: {
            ...state.locations[location__id].guide.room_info,
            getInProgress: true, getStatus: {},
          },
        },
      },
    },
  };
};

export const getGuideRoomInfoSuccess = (state, action) => {
  const { data, params: { location__id, guest_id } } = action.payload;
  if (!location__id || !guest_id) return state;
  let { room_data } = data;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        guide: {
          ...state.locations[location__id].guide,
          room_info: {
            // ...state.locations[location__id].guide.room_info,
            ...room_data,
            getInProgress: false, getStatus: {
              success: true,
            },
          },
        },
      },
    },
  };
};

export const getGuideRoomInfoError = (state, action) => {
  const { error, errorMessage, params } = action.payload;
  if (!params) return state;
  const { location__id, guest_id }= params;
  if (!location__id || !guest_id) return state;
  return {
    ...state,
    locations: {
      ...state.locations,
      [location__id]: {
        ...state.locations[location__id],
        guide: {
          ...state.locations[location__id].guide,
          room_info: {
            ...state.locations[location__id].guide.room_info,
            error,
            errorMessage,
            getInProgress: false, getStatus: {
              error: true,
            },
          },
        },
      },
    },
  };
};
