import { combineReducers } from "redux";
import windowReducer from "./windowReducer";
import appReducer from "./appReducer";
import userReducer from "./userReducer";
import locationReducer from "./locationReducer";
import engageReducer from "./engageReducer";

const allReducers = combineReducers({
  windowReducer: windowReducer,
  appReducer: appReducer,
  userReducer: userReducer,
  locationReducer: locationReducer,
  engageReducer: engageReducer,
});

export default allReducers;
